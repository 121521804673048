import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import withContentfulBlocks from '../components/withContentfulBlocks'

class SignUpIndex extends React.Component {
  render () {
    return (
      <ContentfulPage
        blocks={this.props.blocks}
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')} / Sign up now`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}/signup`}
        footerColor='white'
      />
    )
  }
}

export default withContentfulBlocks(SignUpIndex)

export const pageQuery = graphql`
  query SLSignUpQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "dlDvCybjsRnipXZfEiX4x" }) {
      ...PageBlocksFragment
    }
  }
`
